//#region WEB3

import * as process from 'process';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WindowGlobal = Window & { global: Window & { process?: any } };

(window as unknown as WindowGlobal).global = window;
(window as unknown as WindowGlobal).global.process = process;

//#endregion WEB3
